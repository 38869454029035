import React, { useContext, useState, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import { Rate, notification } from "antd";
import PropTypes from "prop-types";
import cx from "classnames";

import { AuthContext } from "../../widgets";
import { AVERAGE_RATING, UPDATE_RATING } from "../../queries/queries.graphql";
import { useThemeContext } from "../Layout/ThemeContext";

import "./Rating.scss";
import { removeEmptyKeys } from "../../helpers";

export default function Rating({ average, newsId }) {
	const { theme } = useThemeContext();
	const { user } = useContext(AuthContext);

	const [updateRating, { called }] = useMutation(UPDATE_RATING);

	const [value, setValue] = useState(average);

	const isLogin = get(user, "isLogin", false);
	const userId = get(user, "id", "");

	const [
		loadRating,
		{
			called: ratingCalled,
			loading: ratingLoading,
			error: ratingError,
			data: ratingData,
		},
	] = useLazyQuery(
		AVERAGE_RATING,
		{
			variables: {
				newsId,
			},
			fetchPolicy: "network-only",
			ssr: false,
		},
	);

	function onChange(value) {
		updateRating({
			variables:
				{
					rating: value,
					userId,
					newsId,
				},
		})
			.then(() => {
				notification.success({
					message: "Ваш голос учтен",
				});

				loadRating();
			})
			.catch(updateRatingError => {
				console.error("update rating error", { updateRatingError });
				notification.error({
					message: "Вы уже проголосовали",
				});
			});
	}

	useEffect(() => {
		if (ratingCalled) {
			if (ratingData && !ratingError) {
				const rating = get(ratingData, "news_ratings_aggregate.aggregate.avg.rating");

				setValue(rating);
			} else if (ratingError) {
				console.error("ratingError load error", { ratingError });
			}
		}
	}, [ratingLoading]);

	return (
		<div
			className={"rating"}
			onClick={
				() => {
					if (!isLogin) {
						notification.error({
							message: "Войдите в аккаунт, чтобы проголосовать",
						});
					}
				}
			}
		>
			<div
				className={"h5"}
				style={removeEmptyKeys({
					fontFamily: theme.fontFamily,
					fontSize: theme.fontSize.text,
					lineHeight: theme.lineHeight.text,
					color: theme.color.text,
				})}
			>
				Оцените эту новость
			</div>
			<Rate
				className={cx("mb-3", { black: !!theme.color.text })}
				disabled={!isLogin || called}
				value={value}
				onChange={onChange}
			/>
		</div>
	);
}

Rating.propTypes = {
	average: PropTypes.number,
	newsId: PropTypes.number,
};

Rating.defaultProps = {
	average: 0,
};

