import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import { get, compact, last, has } from "lodash";
import PropTypes from "prop-types";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import moment from "moment";

import { ContentBlock, MoreTopics } from "../../components/Blocks";
import Pages from "../../components/Pages";
import { makeUrl, removeEmptyKeys, removePreloader, sorter } from "../../helpers/index";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { Share } from "../../widgets";
import Contents from "../../components/Contents";
import Page404 from "../404";
import Rubrics from "../../components/Rubrics";
import Rating from "../../components/Rating";
import {
	MetaMainEntity,
	MetaDescription,
	MetaImage,
	MetaAuthor,
	MetaName,
	MetaTelephone,
	MetaAddress,
	MetaDateModified,
	MetaMainEntityOfPage,
	MetaIdentifier,
} from "../../components/Meta";
import {
	NEWS_CONTENT,
	NEWS_MAX_UPDATE,
	NEWS_MAX_UPDATE_PREVIEW,
	NEWS_CONTENT_PREVIEW,
} from "../../queries/queries.graphql";
import { useThemeContext } from "../../components/Layout/ThemeContext";

export const query = graphql`
	query newsDataQuery($slug: String!) {
		hasura {
			...NewsContent
		}
	}
`;

export default function NewsPage({ data, location, previewData }) {
	const { theme } = useThemeContext();
	const dateNow = moment().format();
	const dateNowFormated = +new Date();
	const [news, setNews] = useState(get(data, "hasura.v_news[0]", {}));
	const aggregatePrefix = has(previewData, "slug") ? "news_aggregate" : "v_news_updated_at_aggregate";
	const dataPrefix = has(previewData, "slug") ? "news" : "v_news";
	const maxUpdated = new Date(get(data, `hasura.${aggregatePrefix}.aggregate.max.updated_at`, new Date()));

	const previewPath = get(previewData, "slug", "");
	const currentPath = last(compact(get(location, "pathname", "").split("/")));

	const path = previewPath || currentPath;

	const newsOptions = {
		variables: {
			location: path,
			dateNow,
		},
		fetchPolicy: "no-cache",
	};

	const newsOptionsMaxUpdate = {
		variables: {
			location: path,
		},
		fetchPolicy: "no-cache",
	};

	// TODO: May be crutch for queries below?
	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(has(previewData, "slug") ? NEWS_MAX_UPDATE_PREVIEW : NEWS_MAX_UPDATE, newsOptionsMaxUpdate);

	const [loadNews, { called: newsCalled, loading: newsLoading, error: newsError, data: newsData }] = useLazyQuery(has(previewData, "slug") ? NEWS_CONTENT_PREVIEW : NEWS_CONTENT, newsOptions);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, `${aggregatePrefix}.aggregate.max.updated_at`, new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadNews();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load NewsMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (newsData && !newsError) {
			setNews(get(newsData, `${dataPrefix}[0]`, previewPath ? null : news));
			removePreloader(newsCalled);
		} else if (newsError) {
			console.error("Invalid load NewsData", { newsError });
			removePreloader();
		}
	}, [newsLoading]);

	//  Noraml variable declaration
	const themeTextStyle = removeEmptyKeys({
		fontFamily: theme.fontFamily,
		fontSize: theme.fontSize.text,
		lineHeight: theme.lineHeight.text,
		color: theme.color.text,
	});
	const url = makeUrl.news(news);
	const content_blocks = get(news, "content_blocks", []);
	const page_title_full = get(news, "page_title_full", "");
	const page_title_short = get(news, "page_title_short", "");
	const teaser = get(news, "teaser", "");
	const issued_at = get(news, "issued_at", "");
	const author = get(news, "author.name", "");
	const age_rating = get(news, "age_rating", "");
	const imageSrc = get(news, "main_image_preview.src", get(news, "main_image.src", ""));
	const category = get(news, "category", {});
	const rubrics = get(news, "categories_news", []);
	const categories = [{ category }].concat(rubrics); // @insaf, why concat?  TODO: nickname highlight
	const updated_at = get(news, "updated_at", new Date());
	const authorMicrodata = get(news, "author.name", "серафим.рф");
	const rating = get(news, "news_ratings_aggregate.aggregate.avg.rating", 0);
	const newsId = get(news, "id", 0);
	const linkToCalendar = makeUrl.day({
		currentDay: moment(issued_at).format("YYYY-MM-DD"),
		type: "calendar",
	});
	const hidePublishedAt = get(news, "settings.hide_published_at", false);

	const authorStyle = {
		extAlign: "right",
		fontStyle: "italic",
	};

	const linkedNewsBack = get(news, "news", [])
		.filter(item => +new Date(get(item, "news.published_at", "")) <= dateNowFormated)
		.map(item => ({
			path: makeUrl.news(get(item, "news", {})),
			date: !get(item, "news.settings.hide_published_at", false) ? new Date(get(item, "news.issued_at", "")).toLocaleDateString() : null,
			title: get(item, "news.title_full", ""),
			src: get(item, "news.main_image.src", ""),
			issued_at: new Date(get(item, "news.issued_at", "")),
		}),
		);

	const linkedNewsFeature = get(news, "linkedNews", [])
		.filter(item => +new Date(get(item, "linkedNews.published_at", "")) <= dateNowFormated)
		.map(item => ({
			path: makeUrl.news(get(item, "linkedNews", {})),
			date: !get(item, "linkedNews.settings.hide_published_at", false) ? new Date(get(item, "linkedNews.issued_at", "")).toLocaleDateString() : null,
			title: get(item, "linkedNews.title_full", ""),
			src: get(item, "linkedNews.main_image.src", ""),
			issued_at: new Date(get(item, "linkedNews.issued_at", "")),
		}),
		);

	const linkedArticles = get(news, "linkedArticles", [])
		.filter(item => +new Date(get(item, "article.published_at", "")) <= dateNowFormated)
		.map(item => ({
			path: makeUrl.mediaArticle(get(item, "article", {})),
			date: !get(item, "article.settings.hide_published_at", false) ? new Date(get(item, "article.issued_at", "")).toLocaleDateString() : null,
			title: get(item, "article.title_full", ""),
			src: get(item, "article.main_image.src", ""),
			issued_at: new Date(get(item, "article.issued_at", "")),
		}),
		);

	const linkedNewAndArticles = [...linkedNewsBack, ...linkedNewsFeature, ...linkedArticles].sort((a, b) => sorter(b.issued_at, a.issued_at));

	return news ? (
		<Pages entity={news} url={url} >
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs
						currentLabel={page_title_full}
						pageContext={
							{
								item: {
									title_full: "Все новости",
									slug: "news",
								},
								item2: {
									title_full: get(category, "title_full", ""),
									slug: get(category, "slug", ""),
								},
							}
						}
					/>
				</div>
			</ContentBlock>
			<ContentBlock key={"media-content"}>
				<div className={"container"} itemScope itemType="https://schema.org/NewsArticle">
					<MetaIdentifier content={newsId} />
					<MetaMainEntity content={url} />
					<MetaMainEntityOfPage content={url} />
					<MetaAuthor content={authorMicrodata} />
					<MetaDescription content={teaser} />
					<div itemProp="publisher" itemScope itemType="https://schema.org/Organization">
						<MetaName content={"АНО «УК «Саровско-Дивеевский кластер»"} />
						<MetaTelephone />
						<div itemProp="logo" itemScope itemType="https://schema.org/ImageObject">
							<link itemProp="url image" href={"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png"} />
						</div>
						<MetaAddress content={"г.Нижний Новгород, территория Кремль, к.14."} />
					</div>
					<MetaDateModified content={updated_at} />
					<MetaImage content={imageSrc} />

					<h1 className={"h1"}
						itemProp="headline"
						style={{
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.h1,
							lineHeight: theme.lineHeight.h1,
							color: theme.color.text,
						}}
					>
						{page_title_full}
					</h1>
					<div className={"d-flex justify-content-between"}>
						<div
							itemProp="articleSection"
							style={themeTextStyle}
						>
							<Rubrics items={categories} />
							{
								age_rating && (
									<span className={"age_rating m-3"}>{age_rating}</span>
								)
							}
						</div>
						{
							!hidePublishedAt && issued_at && (
								<div
									className={"text-block__subtitle_date"}
									itemProp="datePublished"
									content={moment(issued_at).format("YYYY-MM-DD")}
									style={themeTextStyle}
								>
									<Link to={linkToCalendar}>
										{moment(issued_at).locale("ru").format("DD.MM.YYYY")}
									</Link>
								</div>
							)
						}
					</div>

					<Contents items={content_blocks} date={updated_at} articleBody={"articleBody"}/>

					{author && (
						<div
							className={"pb-5"}
							style={Object.assign({}, authorStyle, themeTextStyle)}
						>
							{author}
						</div>
					)}
				</div>
			</ContentBlock>

			<ContentBlock>
				<div className={"container"}>
					<Rating average={rating} newsId={newsId}/>
				</div>
			</ContentBlock>

			<MoreTopics items={linkedNewAndArticles} />

			<ContentBlock mount={!!url} key={"shareButtons"}>
				<div className={"container"}>
					<Share
						url={url}
						pageTitleShort={page_title_short}
						pageTitleFull={page_title_full}
						imageSrc={imageSrc}
					/>
				</div>
			</ContentBlock>
		</Pages>
	) : (
		<Page404 location={location} />
	);
}

NewsPage.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
	pageContext: PropTypes.object,
	previewData: PropTypes.object,
};

NewsPage.defaultProps = {
	data: {},
	location: {},
	pageContext: {},
	previewData: {},
};
