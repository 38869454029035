import React from "react";
import { Link } from "gatsby";
import { get } from "lodash";
import PropTypes from "prop-types";

import { makeUrl, removeEmptyKeys } from "../../helpers/index";
import { useThemeContext } from "../Layout/ThemeContext";
import "./Rubrics.scss";

export default function Rubrics({ items, entityType }) {
	const { theme } = useThemeContext();

	function getPath(entityType) {
		return category => {
			const maker = {
				news: makeUrl.allNewsBySlug(category),
				pages: makeUrl.allArticlesBySlug(category),
			};

			return maker[entityType];
		};
	}

	return (
		<div className={"rubrics"}>
			{
				items.map(({ category }, idx) =>
					<Link
						key={`category-news-${idx}`}
						to={getPath(entityType)(category)}
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.text,
						})}
					>
						{get(category, "title_full", "")}
					</Link>,
				)
			}
		</div>
	);
}

Rubrics.propTypes = {
	items: PropTypes.array,
	entityType: PropTypes.string.isRequired,
};

Rubrics.defaultProps = {
	items: [],
	entityType: "news",
};

